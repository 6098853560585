<template>
    <div>
        <urlList ></urlList>
    </div>
</template>
<script>

import urlList from '@/components/custom/service_url/list.vue';

export default {
    components: {
        urlList
    },
    data () {
        let self = (window.x = this)
        return {
        }
    },
    methods: {
        demo () {
        },
        // end 
    },
    mounted () {
        // this.getData()
    },
    watch: {},
    computed: {}
}
</script>
