<template>
  <div>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="true"
    >
        <urlEdit :url="edit_url" @closePage="closeEditPage"></urlEdit>
    </Drawer>  

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="4" style="text-align: left">
            <Select v-model="sport_id" @on-change="onSportidChange">
                <Option :value='0' label="全部">  </Option>
                <Option v-for="item in $conf.sports" :value="item.id" :key="item.id" :label="item.name"></Option>
            </Select>
        </Col>
        <Col span="16" style="text-align: right">
            <Button type="success"  @click="drawer_edit=true">新增</Button>
        </Col>
    </Row>
    <br />
    <Table 
        max-height="600" 
        stripe 
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </Table>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getUrls } from '@/api/custom/service_url';
import urlEdit from './edit.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
            h(
                'Button',
                {
                    style: {
                        margin: '2px 2px'
                    },
                    props: {
                        type: 'warning',
                        size: 'small'
                    }
                },
                '删除'
            )
        ]
    )
}

const editButton = (vm, h, params) => {
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    vm.edit_url = params.row.url;
                    vm.drawer_edit = true;
                }
            }
        },
        '编辑'
    )
}

export default {
    name: "url-list",
    // props: {
    //     sport_id: Number,   // 运动id
    // },
    components: {
        urlEdit
    },
    data () {
        let self = (window.x = this)
        return {
            sport_id: 0,
            drawer_edit: false,
            edit_url : '',
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: '路由',
                    align: 'center',
                    // width: 200,
                    key: 'url',
                },
                {
                    title: '名称',
                    align: 'center',
                    // width: 200,
                    key: 'name',
                },
                {
                    title: '中文名称',
                    align: 'center',
                    // width: 200,
                    key: 'name_zh',
                },
                {
                    title: '英文名称',
                    align: 'center',
                    // width: 200,
                    key: 'name_en',
                },
                {
                    title: '版本',
                    align: 'center',
                    // width: 200,
                    key: 'version',
                },
                {
                    title: 'sport_id',
                    align: 'center',
                    // width: 200,
                    key: 'sport_id',
                },
                {
                    title: 'sport_name',
                    // width: 200,
                    align: 'center',
                    key: 'sport_name',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let sport = self.$conf.sports[currentRow.sport_id]
                        return h( 'span', sport? sport.name : '未知id: '+ String(currentRow.sport_id) )
                    }
                },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'center',
                    // width: 120,
                    render (h, params) {
                        return [ editButton(self, h, params), ]
                    }
                }
            ],
            data: [],
        }
    },
    methods: {
        onSportidChange(sport_id){
            this.current_page = 1;
            this.getData()
        },
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        // end
        getData () {
            this.loading = true;
            // 搜索日期
            var params = {
                sport_id: this.sport_id,
                search_key: this.search_key,
                page: this.current_page,
            }
            getUrls(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
            self.getData();
        },
        // end 
    },
    mounted () {
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>