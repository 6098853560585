<template>
    <div>
        <Spin size="large" fix v-if="spinShow"></Spin>
        <Form label-position="left" :label-width="80" >
            <FormItem label="路由: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.url" :disabled="Boolean(url)"></Input>
                    </Col>
                </Row>
                <span class='text-assist'>路由地址不允许重复</span>
            </FormItem>

            <FormItem label="名称(描述): " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="中文名称: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_zh" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="英文名称: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.name_en" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="版本: " >
                <Row>
                    <Col span="20" >
                        <Input v-model="data.version" ></Input>
                    </Col>
                </Row>
            </FormItem>
            <!-- <FormItem label="sport_id: " >
                <Row>
                    <Col span="20" >
                        <Input-number v-model="data.sport_id"  :min="0"></Input-number>
                    </Col>
                </Row>
            </FormItem> -->
            <FormItem label="sport_name: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.sport_id">
                            <Option v-for="item in $conf.sports" :value="item.id" :key="item.id" :label="item.name"></Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
            <FormItem label="是否删除: ">
                <Row>
                    <Col span="20" >
                        <Select v-model="data.deleted" >
                            <Option :value="1" key="1">是</Option>
                            <Option :value="0" key="0">否</Option>
                        </Select>
                    </Col>
                </Row>
            </FormItem>
        </Form>
        <Row  align="middle">
            <Button type="primary"  @click="save" long size="default">保存</Button>
        </Row>
    </div>
</template>
<script>

import { getUrls, editUrls } from '@/api/custom/service_url';

export default {
    name: "url-edit",
    props: {
        url: String,   // 路由
    },
    components: {
    },
    data () {
        let self = (window.x = this)
        return {
            spinShow: false,
            data: {
                deleted: 0,
                sport_id: 0,
            },
        }
    },
    methods: {
        // end 
        getData () {
            let self = this;
            if (self.url && self.url.length > 0){
                this.spinShow = true;
                // 搜索日期
                var params = {
                    "url" : this.url,
                    "page" : 1, 
                }
                getUrls( params ).then(response => {
                    // console.log(response)
                    if (response.data.code == 0) {
                        if (response.data.data.list && response.data.data.list.length > 0){
                            this.data = response.data.data.list[0];
                        }else{
                            this.data = {}
                        }
                        self.spinShow = false
                    } else {
                        self.$Message.error(response.data.msg)
                    }
                })
            }else{
            }
            
        },
        // end 
        emit(func_name){
            this.$emit('closePage', func_name)
        },
        save() {
            let self = this;
            let params = {
                url: self.data.url,
                name: self.data.name,
                name_zh: self.data.name_zh,
                name_en: self.data.name_en,
                version: self.data.version,
                sport_id: self.data.sport_id,
                deleted: self.data.deleted,
            }
            editUrls(params).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                    self.emit("save")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },
    },
    mounted () {
        // let self = this;
        // self.getData();
    },
    watch: {
        'url': {
            handler(value){
                let self = this;
                if (value.length >= 0){
                    // console.log("Detail Get Data", value);
                    self.getData();
                };
            },
            deep: true,
	        immediate: true,  // 深度触发
        },
        // end 
    },
    computed: {
    }
}
</script>

<style scoped>
    /* @import "detail.css" */
</style>