import http from '@/utils/http';

// 获取
export const getUrls = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url/list',
        method: 'get',
        params: params
    })
}

// 更新
export const editUrls = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url/edit',
        method: 'post',
        data: params
    })
}

// 获取信息
export const getUrlInfos = (params) => {
    return http.request({
        url: '/v1/cms_api/custom/url/info',
        method: 'get',
        params: params
    })
}